import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { QuestionService } from '../../services/question.service';
import { Question } from '../../models/question.model';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utility.service';
import { AlertService } from '../../services/alert.service';
import { CONFIRM_CHANGES, DEFAULT_ERROR_MESSAGE, USER_TYPE, LESS_OPTION_COMMNENT, MIN_OPTION_COUNT } from '../../constants/app.constant';
import { LoaderService } from '../../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { TagsModalComponent } from '../tags-modal/tags-modal.component';

@Component({
  selector: 'question-post-edit',
  templateUrl: './question-post-edit.component.html',
  styleUrls: ['./question-post-edit.component.css'],
  providers: [QuestionService]
})
export class QuestionPostEditComponent implements OnChanges {
  @Input() question;
  @Input() isEditMode;
  @Input() index: number;
  @Output() questionEdited = new EventEmitter<boolean>();
  @Output() onDeleteQuestion = new EventEmitter<any>();

  private qId;
  public stem = '';
  public fileNames = [''];
  public explanation = '';
  public additional_explanation = '';
  public options = {
    optionList: [{ val: 'Option 1' }, { val: 'Option 2' }, { val: 'Option 3' }, { val: 'Option 4' }, { val: 'Option 5' }],
    correctAnswer: 'Option 1'
  };
  public previewImages = [];
  public reference = {
    refList: []
  };
  public videoLinks = {
    refList: []
  };
  public googleDocLinks = {
    refList: []
  }
  public categories = {
    discipline: [],
    organSystem: [],
    examType: []
  };
  public author: string;
  public date: string | Date;
  public questionId: string;
  public imageUploadData: FormData = {} as FormData;
  public questionForm: FormGroup;
  public deleteMode: boolean = false;
  user: User;
  public userId: string;
  public isAdmin: boolean;
  private prevQuestionState: Question;
  comment;
  selectedTagsForQues = {};

  constructor(private questionService: QuestionService,
    private utilityService: UtilityService,
    private alertSrvice: AlertService,
    private userService: UserService,
    private loaderService: LoaderService,
    private matDialog: MatDialog,
  ) {
    this.user = userService.getCurrentUser();
    this.userId = this.user.userId;
    this.isAdmin = this.user.userType === USER_TYPE.ADMIN || this.user.userType === USER_TYPE.SUPER_ADMIN;
  }

  ngOnInit() {
    this.questionForm = new FormGroup({
      stemControl: new FormControl('', [Validators.required]),
      explanationControl: new FormControl('', [Validators.required]),
      contentFeedback: new FormGroup({
        hasIssue: new FormControl(''),
        isResolved: new FormControl('')
      })
    });
    this.initQuestion();

    if (!this.utilityService.tagListData.length) {
      this.getAllQuestionTags();
    }
  }

  ngOnChanges(changes) {
    this.question = changes.question ? changes.question.currentValue : {};
    this.index = changes.index ? changes.index.currentValue : 0;
    this.initQuestion();
  }


  private initQuestion() {
    this.qId = (this.question && this.question.qId) ? this.question.qId : 0;
    this.fileNames = (this.question && this.question.image) ? this.question.image.imageList : null;
    this.stem = (this.question && this.question.stem) ? this.question.stem : [''];
    this.explanation = (this.question && this.question.explanation) ? this.question.explanation : '';
    this.additional_explanation = (this.question && this.question.additionalExplanation) ? this.question.additionalExplanation : '';
    this.options = (this.question && this.question.option) ? this.question.option : this.options;
    this.comment = (this.question && this.question.comment) ? this.question.comment : null;
    this.reference = (this.question && this.question.reference) ? this.question.reference : {
      refList: []
    };
    this.videoLinks = (this.question && this.question.videoLinks) ? this.question.videoLinks : {
      refList: []
    };
    this.googleDocLinks = (this.question && this.question.googleDocLinks) ? this.question.googleDocLinks : {
      refList: []
    }
    this.categories = (this.question && this.question.categories) ? this.question.categories : {
      discipline: [],
      organSystem: [],
      examType: []
    };
    this.author = (this.question && this.question.userEmail) ? this.question.userEmail : 'Unknown';
    this.date = (this.question && this.question.createdAt) ? this.question.createdAt : 'n/a';
    this.questionId = (this.question && this.question.qId) ? this.question.qId : '';
    if (this.isEditMode) {
      this.prevQuestionState = this.utilityService.clone(this.question) as Question //Object.assign({}, this.question);
      this.deleteMode = (this.user.userType === 'admin' || this.user.userType === 'superAdmin');
    }
    this.previewImages = this.fileNames ? [].concat(this.fileNames) : [];

    if (this.questionForm && this.isAdmin && this.question && this.question.contentFeedback) {
      delete this.question.contentFeedback._id;
      this.questionForm.get('contentFeedback').setValue(this.question.contentFeedback);
    }
  }

  ngDoCheck(): void {
    // console.log('googledoclink >> ', this.googleDocLinks, 'video link', this.videoLinks);
  }

  async onSubmit(imageData) {
    const currentUser = this.user,
      userId = currentUser ? currentUser.userId : '';

    //validate: correctAnswer is present in the optionList
    const optionValList: Array<string> = this.options.optionList.map(op => op.val);

    // Define a regex pattern to match "Option i"
    const regexPattern = /^Option \d+$/;

    // Use the filter method with the regex test method to exclude matched options
    const filteredOptions: Array<string> = optionValList.filter(option => regexPattern.test(option));
    if (filteredOptions != undefined && filteredOptions.length > 0) {
      this.notifyUser({ status: false, message: "Invalid Options like Option 'i', i=1,2..n are not allowed." });
      return;
    }
    if (!optionValList.includes(this.options.correctAnswer)) {
      this.notifyUser({ status: false, message: "Options and Correct Answer Don't Match!" });
      return;
    }

    if (optionValList.length < MIN_OPTION_COUNT && !this.comment) {
      let confirmData = [{ name: 'Save', model: true, comment: '', hasComment: true }, { name: 'Cancel', model: false }];
      const confirmCmt = await new Promise((resolve) => {
        this.alertSrvice.confirm(confirmData, LESS_OPTION_COMMNENT, (result) => resolve(result.comment));
      });

      if (!confirmCmt) {
        return;
      }

      this.comment = confirmCmt;
    }

    if (optionValList.length >= MIN_OPTION_COUNT) {
      this.comment = null;
    }

    // TODO: move to service
    const formattedRefLinks = this.utilityService.formatRefLinks(this.reference);
    const formattedOptions = this.utilityService.formatOptionList(this.options);
    const formattedVideoLinks = this.utilityService.formatRefLinks(this.videoLinks);
    const formattedGoogleDocLinks = this.utilityService.formatRefLinks(this.googleDocLinks);
    let newFiles;

    if (imageData) {
      newFiles = imageData.isDelete ? imageData.files : (this.fileNames || []).concat(imageData.files);
    }

    const image = {
      imageList: imageData ? newFiles : (this.fileNames && this.fileNames.length) ? this.fileNames : [],
      imageForm: this.imageUploadData
    };

    if (this.isAdmin) {
      this.question = new Question(userId, null, this.stem, image, formattedOptions, this.explanation,
        this.additional_explanation, formattedRefLinks, formattedVideoLinks, formattedGoogleDocLinks, this.categories,
        null, null, null, null, this.questionForm.get('contentFeedback').value, this.comment);
    } else {
      this.question = new Question(userId, null, this.stem, image, formattedOptions, this.explanation,
        this.additional_explanation, formattedRefLinks, formattedVideoLinks, formattedGoogleDocLinks, this.categories,
        null, null, null, null, null, this.comment);
      delete this.question.contentFeedback;
    }

    if (!this.questionService.validateQuestion(this.question)) {
      this.notifyUser({ status: false, message: "Please select at least one option for either [Disciplines or Organ System] and one for [Exam Type]" });
      return;
    }

    if (this.isEditMode) {
      const confirmData = [{ name: 'Save', model: true }, { name: 'Discard', model: false }];

      this.alertSrvice.confirm(confirmData, CONFIRM_CHANGES, (result) => {
        if (result) { //if user selects Save
          this.loaderService.show();
          //update image
          if (image.imageList.length && image.imageForm && (image.imageForm instanceof FormData)) {
            this.questionService.uploadImage(image.imageForm).subscribe(
              res => {
                this.saveEditedQuestion();
                if (imageData) {
                  const newFiles = imageData.isDelete ? imageData.files : (this.fileNames || []).concat(imageData.files);
                  this.fileNames = newFiles;
                  this.previewImages = imageData.isDelete ? imageData.previewImages : this.previewImages.concat(imageData.previewImages);
                }
              },
              err => {
                console.error("Image upload error = ", err);
                this.loaderService.hide();
                this.notifyUser({ status: false, message: 'Failed to upload image.' });
              });
          } else {
            this.saveEditedQuestion();
          }
        } else { // if user selects discard
          this.question = this.prevQuestionState;
          this.initQuestion();
        }
      });
    } else {
      this.loaderService.show();
      if (image.imageList.length && image.imageForm && (image.imageForm instanceof FormData)) {
        this.questionService.uploadImage(image.imageForm).subscribe(
          res => {
            this.saveNewQuestion();
          },
          err => {
            console.error("Image upload error = ", err);
            this.loaderService.hide();
            this.notifyUser({ status: false, message: 'Failed to upload image.' });
          });
      } else {
        this.saveNewQuestion();
      }
    }
  }

  saveEditedQuestion() {
    this.questionService.editQuestion(this.qId, this.questionService.formatQuestionToUpload(this.question))
      .subscribe((resp: any) => {
        this.questionEdited.emit(true);
        this.loaderService.hide();
        this.notifyUser({ status: true, message: resp['result'].message });
      }, err => {
        this.loaderService.hide();
        this.notifyUser({ status: false, message: err.error.error || "Oops an error occurred" });
      });
  }

  saveNewQuestion() {
    this.questionService.storeQuestions(this.questionService.formatQuestionToUpload(this.question))
      .subscribe(
        (resp: any) => {
          this.loaderService.hide();
          this.notifyUser({ status: true, message: resp['result'].message });
        }, err => {
          this.loaderService.hide();
          this.notifyUser({ status: false, message: err.error.error || "Oops an error occurred" });
        });
  }

  clearForm(form: NgForm) {
    this.qId = null;
    this.stem = '';
    this.reference = { refList: [] };
    this.videoLinks = { refList: [] };
    this.googleDocLinks = { refList: [] };
    this.categories = { discipline: [], organSystem: [], examType: [] };
    form.reset();
  }

  notifyUser(status: any) {
    this.alertSrvice.notifyUser(status);
  }

  onFileSelected(files) {
    let input = new FormData();
    const newPreview = [];

    for (let id in files.fileData) {
      input.append('files', files.fileData[id]);
      newPreview.push(files.fileData[id]);
    }
    this.imageUploadData = input;

    if (this.isEditMode) {
      this.onSubmit({ previewImages: newPreview, files: files.fileNames });
    } else {
      const newFiles = (this.fileNames || []).concat(files.fileNames);
      this.fileNames = newFiles;
      this.previewImages = this.previewImages.concat(newPreview);
    }
  }

  onCategoriesSelected(categories) {
    this.categories = categories;
  }

  deleteQuestion(event) {
    event.preventDefault();
    this.onDeleteQuestion.emit({ index: this.index });
  }

  isEditorOrSuperAdmin(user) {
    return [USER_TYPE.SUPER_ADMIN, USER_TYPE.EDITOR].some((utype) => user.userType === utype);
  }
  isReadOnlyAccess(user) {
    //read-only access for Editor user
    return user.userType === USER_TYPE.EDITOR;
  }

  onImageDelete(data) {
    if (this.isEditMode) {
      for (let i in data.fileImg) {
        if (data.fileImg[i].name) {
          data.fileImg[i] = data.fileImg[i].name;
        }
      }
      this.onSubmit({ previewImages: data.fileImg, files: data.fileImg, isDelete: true });
    } else {
      this.previewImages = [].concat(data.fileImg);

      for (let i in data.fileImg) {
        if (data.fileImg[i].name) {
          data.fileImg[i] = data.fileImg[i].name;
        }
      }

      const newFiles = data.fileImg;
      this.fileNames = newFiles;
    }
  }

  openTagsModal() {
    const dialogRef = this.matDialog.open(TagsModalComponent, {
      width: '60%',
      data: {
       allTags: this.utilityService.tagListData,
       selectedTagsForQues: this.selectedTagsForQues
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result) {
        return;
      }

      const updatedTags = [];

      Object.keys(result).forEach((key) => {
        if (result[key]) {
          updatedTags.push({
            id: key,
            operation: 'ADD'
          });
        }
      });

      if (this.selectedTagsForQues) {
        Object.keys(this.selectedTagsForQues).forEach((key) => {
          if (!result[key]) {
            updatedTags.push({
              id: key,
              operation: 'REMOVE'
            });
          }
        });
      }

      if (updatedTags.length) {
        this.updateTagsForQuestion(updatedTags);
      }
    });
  }

  getAllQuestionTags() {
    this.questionService.getAllTags().subscribe(data => {
      this.utilityService.tagListData = data;
    });
  }

  mapSelectedTags(selectedTags) {
    const mappedObj = {};
    selectedTags.forEach(item => mappedObj[item.tag.tagId] = true);
    return mappedObj;
  }

  getTagsForQuestion() {
    this.questionService.getQuesTags(this.qId).subscribe(data => {
      this.selectedTagsForQues = this.mapSelectedTags(data);
      this.openTagsModal();
    });
  }

  updateTagsForQuestion(tags) {
    const params = {
      userId: this.userId,
      tags
    };

    this.questionService.updateQuesTags(this.qId, params).subscribe(
      res => {
        this.notifyUser({ status: true, message: 'Tags updated successfully!!!' });
      },
      err => {
        this.notifyUser({ status: false, message: 'Failed to update tags!!!' });
      }
    );
  }
}
